.queryContinue .page-header .img {
  width: 54%;
  max-width: 183px;
}
.queryContinue__block {
  margin: 0;
  padding: 35px 15px;
  text-align: center;
}
.queryContinue__block .inner {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 1100px;
}
.queryContinue__questionnaire {
  margin: 70px 0;
  padding: 0 15px;
  position: relative;
  display: none;
  height: 0;
  visibility: hidden;
  opacity: 0;
}
.queryContinue__questionnaire::before, .queryContinue__questionnaire::after {
  content: "";
  display: none;
  width: 30px;
  height: 20px;
  background-image: url(../images/common/icon_arw_down.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  position: absolute;
  left: 50%;
}
.queryContinue__questionnaire::before {
  bottom: 100%;
  transform: translate(-50%, -20px);
}
.queryContinue__questionnaire::after {
  top: 100%;
  transform: translate(-50%, 15px);
}
.queryContinue__questionnaire.open {
  display: block;
  height: auto;
  visibility: visible;
  opacity: 1;
}
.queryContinue__questionnaire.open::before {
  display: block;
}
.queryContinue__questionnaire.q1 {
  margin-top: 0;
}
.queryContinue__questionnaire.q1::before {
  display: none;
}
.queryContinue__questionnaire.q4.on::after {
  display: block;
}
.queryContinue__questionnaire .inner {
  padding: 20px 12px;
  max-width: 900px;
  text-align: center;
  background-color: #f1f6f9;
}
.queryContinue__questionnaire .questionnaire__q {
  margin: 0 auto;
  padding-left: 30px;
  width: 270px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  position: relative;
}
.queryContinue__questionnaire .questionnaire__q .icon_q {
  color: #0072bc;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  position: absolute;
  left: 0;
  top: -5px;
}
.queryContinue__questionnaire .questionnaire__a {
  margin-top: 25px;
}
.queryContinue__questionnaire .questionnaire__a .flexBox {
  display: flex;
  margin: 0 -10px;
}
.queryContinue__questionnaire .questionnaire__a .flexItem {
  padding: 0 10px;
  width: 50%;
}
.queryContinue__questionnaire .questionnaire__a a {
  width: 100%;
  font-size: 15px;
}
.queryContinue__bottom {
  display: none;
  padding-top: 10px;
  padding-bottom: 50px;
  position: relative;
}
.queryContinue__bottom::before {
  content: "";
  width: 30px;
  height: 20px;
  background-image: url(../images/common/icon_arw_down.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translate(-50%, -20px);
}
.queryContinue__bottom.open {
  display: block;
}
.queryContinue__bottom .link {
  margin-top: 10px;
}
.queryContinue__bottom .link a.e_btn {
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  height: 50px;
  max-width: 345px;
}
.queryContinue__bottom .link a.e_btn span {
  display: block;
  text-align: center;
}
.queryContinue__bottom .link a.e_btn small {
  display: block;
  margin: 5px 0 0;
  font-size: 12px;
  text-align: center;
  line-height: 1.2;
}
.queryContinue__bottom.msg_2 {
  text-align: center;
}
.queryContinue__bottom.msg_2 .t2_1 {
  font-size: 14px;
  line-height: 2.25;
  letter-spacing: 0;
}
.queryContinue__bottom.msg_2 .contactBtn {
  margin-top: 20px;
}
.queryContinue__bottom.msg_2 .contactBtn a {
  margin-top: 5px;
  line-height: 1.8;
}
.queryContinue__bottom.msg_2 .t2_2 {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.66;
  letter-spacing: 0.05em;
}
.queryContinue__bottom.msg_2 .t2_3 a.tel {
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
}
.queryContinue__bottom.msg_2 .t2_4 {
  font-size: 12px;
  line-height: 2.25;
  letter-spacing: 0;
}
.queryContinue .e_btn.type_input {
  width: 100%;
  height: 45px;
  color: #0072bc;
  background: #fff;
  border: 1px solid #0072bc;
  transition: all 0.3s ease;
  cursor: pointer;
}
.queryContinue .e_btn.type_input::after {
  width: 14px;
  height: 14px;
  background-image: url(../images/common/icon_arw_btn3.svg);
  right: 10px;
  transform: translateY(-50%) rotate(90deg);
}
.queryContinue .e_btn.type_input:hover {
  color: #fff;
  background: #0072bc;
}
.queryContinue .e_btn.type_input:hover::after {
  background-image: url(../images/common/icon_arw_btn2.svg);
  right: 10px;
  top: 54%;
}
.queryContinue .e_btn.type_input.on {
  color: #fff;
  background: #0072bc;
  pointer-events: none;
}
.queryContinue .e_btn.type_input.on::after {
  background-image: url(../images/common/icon_arw_btn2.svg);
}
.queryContinue .e_btn.type_input.on:hover::after {
  top: 50%;
}

@media screen and (min-width: 768px) {
  .queryContinue .page-header .img {
    width: 100%;
    max-width: 365px;
  }
  .queryContinue__block {
    padding: 75px 15px 73px;
    text-align: center;
  }
  .queryContinue__questionnaire {
    margin: 108px 0;
    padding: 0 15px;
  }
  .queryContinue__questionnaire::before {
    transform: translate(-50%, -35px);
  }
  .queryContinue__questionnaire::after {
    transform: translate(-50%, 30px);
  }
  .queryContinue__questionnaire .inner {
    padding: 45px 60px 39px;
  }
  .queryContinue__questionnaire .questionnaire__q {
    display: inline-block;
    padding-left: 50px;
    width: auto;
    font-size: 24px;
    text-align: center;
  }
  .queryContinue__questionnaire .questionnaire__q .icon_q {
    font-size: 30px;
  }
  .queryContinue__questionnaire .questionnaire__a {
    margin-top: 32px;
  }
  .queryContinue__questionnaire .questionnaire__a .flexBox {
    display: flex;
    margin: 0 -25px;
  }
  .queryContinue__questionnaire .questionnaire__a .flexItem {
    padding: 0 25px;
    width: 50%;
  }
  .queryContinue__questionnaire .questionnaire__a a {
    font-size: 20px;
  }
  .queryContinue__bottom {
    padding-top: 0;
    padding-bottom: 100px;
  }
  .queryContinue__bottom::before {
    transform: translate(-50%, -35px);
  }
  .queryContinue__bottom .t1_1 {
    text-align: center;
  }
  .queryContinue__bottom .link a.e_btn {
    height: 72px;
    max-width: 481px;
  }
  .queryContinue__bottom .link a.e_btn small {
    margin: 10px 0 0;
  }
  .queryContinue__bottom.msg_2 .t2_1 {
    font-size: 16px;
  }
  .queryContinue__bottom.msg_2 .contactBtn {
    margin-top: 50px;
  }
  .queryContinue__bottom.msg_2 .contactBtn a {
    width: 482px;
    height: 72px;
    font-size: 24px;
  }
  .queryContinue__bottom.msg_2 .t2_2 {
    margin-top: 40px;
    font-size: 18px;
  }
  .queryContinue__bottom.msg_2 .t2_3 a.tel {
    margin-top: 5px;
    font-size: 20px;
  }
  .queryContinue__bottom.msg_2 .t2_3 a.tel .fa-phone {
    width: 19px;
    height: 19px;
  }
  .queryContinue__bottom.msg_2 .t2_4 {
    margin-top: -5px;
    font-size: 16px;
  }
  .queryContinue .e_btn.type_input {
    height: 72px;
  }
  .queryContinue .e_btn.type_input::after {
    width: 24px;
    height: 24px;
    right: 30px;
  }
  .queryContinue .e_btn.type_input:hover::after {
    right: 30px;
    top: 60%;
  }
  .queryContinue .e_btn.type_input.on:hover::after {
    top: 50%;
  }
}