.queryContinue {
  .page-header {
    .img {
      width: 54%;
      max-width: 183px;
    }
  }

  &__block {
    margin: 0;
    padding: 35px 15px;
    text-align: center;

    .inner {
      margin: 0 auto;
      padding: 0;
      width: 100%;
      max-width: 1100px;
    }
  }

  &__questionnaire {
    margin: 70px 0;
    padding: 0 15px;
    position: relative;

    display: none;
    height: 0;
    visibility: hidden;
    opacity: 0;

    &::before,
    &::after {
      content: "";
      display: none;
      width: 30px;
      height: 20px;
      background-image: url(../images/common/icon_arw_down.svg);
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: contain;
      position: absolute;
      left: 50%;
    }

    &::before {
      bottom: 100%;
      transform: translate(-50%, -20px);
    }

    &::after {
      top: 100%;
      transform: translate(-50%, 15px);
    }

    &.open {
      display: block;
      height: auto;
      visibility: visible;
      opacity: 1;

      &::before {
        display: block;
      }
    }

    &.q1 {
      margin-top: 0;

      &::before {
        display: none;
      }
    }

    &.q4 {
      &.on {
        &::after {
          display: block;
        }
      }
    }

    .inner {
      padding: 20px 12px;
      max-width: 900px;
      text-align: center;
      background-color: #f1f6f9;
    }

    .questionnaire__q {
      margin: 0 auto;
      padding-left: 30px;
      width: 270px;
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      position: relative;

      .icon_q {
        color: #0072bc;
        font-size: 20px;
        font-weight: 700;
        font-style: normal;
        position: absolute;
        left: 0;
        top: -5px;
      }
    }

    .questionnaire__a {
      margin-top: 25px;

      .flexBox {
        display: flex;
        margin: 0 -10px;
      }

      .flexItem {
        padding: 0 10px;
        width: 50%;
      }

      a {
        width: 100%;
        font-size: 15px;
      }
    }
  }

  &__bottom {
    display: none;
    padding-top: 10px;
    padding-bottom: 50px;
    position: relative;

    &::before {
      content: "";
      width: 30px;
      height: 20px;
      background-image: url(../images/common/icon_arw_down.svg);
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: contain;
      position: absolute;
      left: 50%;
      bottom: 100%;
      transform: translate(-50%, -20px);
    }

    &.open {
      display: block;
    }

    .link {
      margin-top: 10px;

      a.e_btn {
        flex-wrap: wrap;
        margin: 0 auto;
        width: 100%;
        height: 50px;
        max-width: 345px;

        span {
          display: block;
          text-align: center;
        }

        small {
          display: block;
          margin: 5px 0 0;
          font-size: 12px;
          text-align: center;
          line-height: 1.2;
        }
      }
    }

    &.msg_2 {
      text-align: center;

      .t2_1 {
        font-size: 14px;
        line-height: 2.25;
        letter-spacing: 0;
      }

      .contactBtn {
        margin-top: 20px;

        a {
          margin-top: 5px;
          line-height: 1.8;
        }
      }

      .t2_2 {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.66;
        letter-spacing: 0.05em;
      }

      .t2_3 {
        a.tel {
          font-size: 18px;
          font-weight: 700;
          text-decoration: none;

          .fa-phone {
          }
        }
      }

      .t2_4 {
        font-size: 12px;
        line-height: 2.25;
        letter-spacing: 0;
      }
    }
  }

  .e_btn.type_input {
    width: 100%;
    height: 45px;
    color: #0072bc;
    background: #fff;
    border: 1px solid #0072bc;
    transition: all 0.3s ease;
    cursor: pointer;

    &::after {
      width: 14px;
      height: 14px;
      background-image: url(../images/common/icon_arw_btn3.svg);
      right: 10px;
      transform: translateY(-50%) rotate(90deg);
    }

    &:hover {
      color: #fff;
      background: #0072bc;

      &::after {
        background-image: url(../images/common/icon_arw_btn2.svg);
        right: 10px;
        top: 54%;
      }
    }
  }

  .e_btn.type_input.on {
    color: #fff;
    background: #0072bc;
    pointer-events: none;

    &::after {
      background-image: url(../images/common/icon_arw_btn2.svg);
    }

    &:hover {
      &::after {
        top: 50%;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .queryContinue {
    .page-header {
      .img {
        width: 100%;
        max-width: 365px;
      }
    }

    &__block {
      padding: 75px 15px 73px;
      text-align: center;
    }

    &__questionnaire {
      margin: 108px 0;
      padding: 0 15px;

      &::before {
        transform: translate(-50%, -35px);
      }

      &::after {
        transform: translate(-50%, 30px);
      }

      .inner {
        padding: 45px 60px 39px;
      }

      .questionnaire__q {
        display: inline-block;
        padding-left: 50px;
        width: auto;
        font-size: 24px;
        text-align: center;

        .icon_q {
          font-size: 30px;
        }
      }

      .questionnaire__a {
        margin-top: 32px;

        .flexBox {
          display: flex;
          margin: 0 -25px;
        }

        .flexItem {
          padding: 0 25px;
          width: 50%;
        }

        a {
          font-size: 20px;
        }
      }
    }

    &__bottom {
      padding-top: 0;
      padding-bottom: 100px;

      &::before {
        transform: translate(-50%, -35px);
      }

      .t1_1 {
        text-align: center;
      }

      .link {
        a.e_btn {
          height: 72px;
          max-width: 481px;

          small {
            margin: 10px 0 0;
          }
        }
      }

      &.msg_2 {
        .t2_1 {
          font-size: 16px;
        }
        .contactBtn {
          margin-top: 50px;

          a {
            width: 482px;
            height: 72px;
            font-size: 24px;
          }
        }
        .t2_2 {
          margin-top: 40px;
          font-size: 18px;
        }
        .t2_3 {
          a.tel {
            margin-top: 5px;
            font-size: 20px;

            .fa-phone {
              width: 19px;
              height: 19px;
            }
          }
        }
        .t2_4 {
          margin-top: -5px;
          font-size: 16px;
        }
      }
    }

    .e_btn.type_input {
      height: 72px;

      &::after {
        width: 24px;
        height: 24px;
        right: 30px;
      }

      &:hover {
        &::after {
          right: 30px;
          top: 60%;
        }
      }
    }

    .e_btn.type_input.on {
      &:hover {
        &::after {
          top: 50%;
        }
      }
    }
  }
}
